
import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {
  title = 'Ad Film Companies in Kerala | Ad Film Production in Kerala';

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title); 
    this.metaService.updateTag(
      { content: 'Are you looking for the best Ad Film Production in Kerala? Then, contact Adpedia Films: one of the leading Ad Film Companies in Kerala & get quality services.'} , 'name="description"'
    );
  }

}
