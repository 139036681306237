import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { portfolioDetailsComponent } from './portfolio-details/portfolio-details.component';
import { PortfolioSahyadriComponent } from './portfolio-sahyadri/portfolio-sahyadri.component';
import { PortfolioComponent } from './portfolio.component';


const routes: Routes = [
    {path: 'portfolio', component: PortfolioComponent},
    {path: 'portfolio/portfolio-details', component: portfolioDetailsComponent},
    {path: 'portfolio/portfolio-sahyadri', component: PortfolioSahyadriComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PortFolioRoutingModule { }