<footer class="pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a class="footer-logo-img" routerLink="/"><img src="assets/img/logo-films.svg" width="100"
                                alt="Logo"></a>
                        <p>Adpedia has been dedicated to providing ad film-making services for more than eight years.
                            The experienced and passionate crew of filmmakers with a knack for innovation ensures
                            exceptional quality and service.</p>
                        <ul>
                            <li><a href="https://www.facebook.com/Adpedia-films-104097221831347" target="_blank"><i
                                        class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://www.instagram.com/adpedia/" target="_blank"><i
                                        class='bx bxl-instagram'></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCoEKAY-s4P0SjnU5W8yT_NA" target="_blank"><i
                                        class='bx bxl-youtube'></i></a></li>
                            <li><a href="https://vimeo.com/user46609578" target="_blank"><i
                                        class='bx bxl-vimeo'></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-instagram">
                        <h3>Instagram</h3>

                        <ul>
                            <li>
                                <a href="#" target="_blank"><img src="assets/img/home-one/instagram1.jpg"
                                        alt="Instagram"></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"><img src="assets/img/home-one/instagram2.jpg"
                                        alt="Instagram"></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"><img src="assets/img/home-one/instagram3.jpg"
                                        alt="Instagram"></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"><img src="assets/img/home-one/instagram4.jpg"
                                        alt="Instagram"></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"><img src="assets/img/home-one/instagram5.jpg"
                                        alt="Instagram"></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"><img src="assets/img/home-one/instagram6.jpg"
                                        alt="Instagram"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-link">
                        <h3>Useful Links</h3>

                        <ul>
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about-us">About</a></li>
                            <li><a routerLink="/services">Services</a></li>
                            <li><a routerLink="/portfolio">Portfolio</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-touch">
                        <h3>Get In Touch</h3>

                        <ul>
                            <li>
                                <i class='bx bxs-phone-call'></i>
                                <h4>Phone:</h4>
                                <a href="tel:+919447768570">+91 94 47 768 570</a>
                            </li>

                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <h4>Email:</h4>
                                <a href="mailto:adpediafilms@gmail.com">adpediafilms@gmail.com</a>
                            </li>
                            <li>
                                <i class='bx bxs-location-plus'></i>
                                <h4>Address:</h4>
                                <span>Adpedia Films Pvt Ltd. <br>
                                    Mecheri Building, Room No:A-1, <br>
                                    Opposite Chalakudy Bus Station</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p>Copyright @2021 Design & Developed by <a href="https://adpedia.in/" target="_blank">Adpedia</a></p>
        </div>
    </div>
</div>