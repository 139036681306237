import { Component, OnInit } from '@angular/core';
import { Fancybox } from '@fancyapps/ui'
@Component({
  selector: 'app-portfolio-sahyadri',
  templateUrl: './portfolio-sahyadri.component.html',
  styleUrls: ['./portfolio-sahyadri.component.scss']
})
export class PortfolioSahyadriComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    Fancybox.bind("[data-fancybox]", {
      infinite: false,
    })

  }

}
