<div class="page-title-area page-title-area-portfolio">
    <div class="container">
        <div class="page-title-item">
            <h2>Project Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li><a routerLink="/portfolio">Portfoliio</a></li>
                <li><span></span></li>
                <li>Project Details</li>
            </ul>
        </div>
    </div>
</div>

<div class="video-details-area pt-100 pb-70">
    <div class="container">

        <div class="row pb-70">
 
            
            <div class="col-lg-6 col-md-12">
                <div class="video-details-item">


                    <div class="video-flim">
                        <h5 class="sub">tv commercial</h5>
                        <h3>Sahyadri Bio Labs</h3>
                        <p>Sahyadri bio labs (SBL) is a specialist in hair care. Years of research based on advanced biotechnology are the basis for the genesis of Amrut Veni range of hair care products.   </p>
                    </div>


                </div>


            </div>



            

        </div>


        <div class="row pb-100">



            <div class="col-lg-10 col-md-12">
                <div class="video-details-item">
                    <div class="video-details-video icon-visible">
                        <img src="assets/img/home-one/work5.jpg" alt="Work">
                        <a href="assets/img/home-one/work5.jpg" data-fancybox><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>


             



            

        </div>




        <div class="row pb-100">


            <div class="col-lg-5 col-md-12 ">
                <div class="video-details-item">
                    <div class="video-introduce  ">
                        <ul>
                            <li>
                                <span>Client</span>
                                <h4>Sahyadri Bio Labs</h4>
                                
                            </li>
                            <li>
                                <span>Date</span>
                                <h4>December 21, 2020</h4>
                                
                            </li>
                            <li>
                                <span>Producer</span>
                                <h4>Adpedia Films</h4>
                                
                            </li>
                            <li>
                                <span>Director</span>
                                <h4>Sarath Kottikal
                                </h4>
                                
                            </li>
                            <li>
                                <span>Cinematography</span>
                                <h4>-
                                </h4>
                                
                            </li>
                             
                        </ul>
                    </div>

                     
                </div>


            </div>



            <div class="col-lg-7 col-md-12  ">
                <div class="video-details-item">
                    <div class="video-details-video icon-visible">
                        <img src="assets/img/portfolio/ps1.jpg" alt="Work">
                        <a href="assets/img/portfolio/ps1.jpg" data-fancybox><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>

        </div>


 




        <div class="row">
            <div class="col-lg-12  col-md-12 video-details-item"> 
                <div class="  video-flim">
                    <h3  >Project Gallery</h3>
                </div>
                </div>
            <div class="col-lg-4 col-md-6 col-md-12">
                <div class="video-details-item">
                    <div class="video-details-video">
                        <img src="assets/img/portfolio/ps5thumb.jpg" alt="Work">
                        <a data-fancybox="psgallery" href="assets/img/portfolio/ps5.jpg" class=""><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>
            <div class="col-lg-4 col-md-6 col-md-12">
                <div class="video-details-item">
                    <div class="video-details-video">
                        <img src="assets/img/portfolio/ps3thumb.jpg" alt="Work">
                        <a data-fancybox="psgallery" href="assets/img/portfolio/ps3.jpg" class=""><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>
            <div class="col-lg-4 col-md-6 col-md-12">
                <div class="video-details-item">
                    <div id="single-images" class="video-details-video">
                        <img src="assets/img/portfolio/ps4thumb.jpg" alt="Work">
                        <a data-fancybox="psgallery" href="assets/img/portfolio/ps4.jpg" class=""><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>
            <div class="col-lg-4 col-md-6 col-md-12">
                <div class="video-details-item">
                    <div id="single-images" class="video-details-video">
                        <img src="assets/img/portfolio/ps2thumb.jpg" alt="Work">
                        <a data-fancybox="psgallery" href="assets/img/portfolio/ps2.jpg" class=""><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-md-6 col-md-12">
                <div class="video-details-item">
                    <div id="single-images" class="video-details-video">
                        <img src="assets/img/portfolio/psthumb.jpg" alt="Work">
                        <a data-fancybox="psgallery" href="assets/img/home-one/work5.jpg" class=""><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-md-6 col-md-12">
                <div class="video-details-item">
                    <div class="video-details-video">
                        <img src="assets/img/portfolio/ps1thumb.jpg" alt="Work">
                        <a data-fancybox="psgallery" href="assets/img/portfolio/ps1.jpg" class=""><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>
             
        </div>



        
    </div>
</div>



 