<div class="page-title-area page-title-area-portfolio">
    <div class="container">
        <div class="page-title-item">
            <h2>Portfolio</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li>Portfolio</li>
            </ul>
        </div>
    </div>
</div>

<div class="week-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-6">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/work5.jpg" alt="Week">
                    <a class="icon"><i class='bx bx-play'></i></a>
                    <div class="weekhover">
                        <div class="inner">
                            <h3>Sahyadri Bio Labs</h3>
                            <p>Sahyadri bio labs (SBL) is a specialist in hair care. Years of research based on advanced biotechnology are the basis for the genesis of Amrut Veni range of hair care products.</p>
                        </div>

                        <div class="btn-wrapper">
                            <a routerLink="/portfolio/portfolio-sahyadri" href="" class="btn">Read Case Study</a>
                            
                            <a href="" routerLink="/portfolio/portfolio-sahyadri"  class="btn"> View More </a>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/work2.jpg" alt="Week">
                    <a  href="#" class="popup-youtube icon"><i class='bx bx-play'></i></a>
                    <div class="weekhover">
                        <div class="inner">
                            <h3>Urban stitches</h3>
                            <p>We take a look at the rich history of pearl diving
                                in the UAE and see how this translates into modern
                                day sporting competition</p>
                        </div>

                        <div class="btn-wrapper">
                            <a routerLink="/portfolio/portfolio-details" href="" class="btn">Read Case Study</a>
                            <a href="" href="#" class="popup-youtube btn"> Watch Video </a>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/work1.jpg" alt="Week">
                    <a class="icon"><i class='bx bx-play'></i></a>
                    <div class="weekhover">
                        <div class="inner">
                            <h3>Sew N Tell - Sunglasses</h3>
                            <p>We take a look at the rich history of pearl diving
                                in the UAE and see how this translates into modern
                                day sporting competition</p>
                        </div>

                        <div class="btn-wrapper">
                            <a routerLink="/portfolio/portfolio-details" href="" class="btn">Read Case Study</a>
                            
                            <a href="" href="#" class="popup-youtube btn"> Watch Video </a>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-6">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/work4.jpg" alt="Week">
                    <a href="#" class="popup-youtube icon"><i class='bx bx-play'></i></a>
                    <div class="weekhover">
                        <div class="inner">
                            <h3>Urban stitches</h3>
                            <p>We take a look at the rich history of pearl diving
                                in the UAE and see how this translates into modern
                                day sporting competition</p>
                        </div>

                        <div class="btn-wrapper">
                            <a routerLink="/portfolio/portfolio-details" href="" class="btn">Read Case Study</a>
                            <a href="" href="#" class="popup-youtube btn"> Watch Video </a>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    </div>
</div>