<div class="page-title-area page-title-area-portfolio">
    <div class="container">
        <div class="page-title-item">
            <h2>Project Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li><a routerLink="/portfolio">Portfoliio</a></li>
                <li><span></span></li>
                <li>Project Details</li>
            </ul>
        </div>
    </div>
</div>

<div class="video-details-area pt-100 pb-70">
    <div class="container">

        <div class="row pb-70">
 
            
            <div class="col-lg-6 col-md-12">
                <div class="video-details-item">


                    <div class="video-flim">
                        <h5 class="sub">tv commercial</h5>
                        <h3>Sahyadri Bio Labs</h3>
                        <p>The FINA World Short Course Championships were due to be held in Abu Dhabi, and our brief was to create the launch film.  Our client, the Abu Dhabi Sports Council, wanted to centre the film around Abu Dhabi’s rich pearl diving  </p>
                    </div>


                </div>


            </div>



            

        </div>


        <div class="row pb-100">



            <div class="col-lg-7 col-md-12">
                <div class="video-details-item">
                    <div class="video-details-video icon-visible">
                        <img src="assets/img/portfolio/p3.jpg" alt="Work">
                        <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                    </div>
                </div>

            </div>


            <div class="col-lg-5 col-md-12">
                <div class="video-details-item">


                    <div class="video-flim">
                        <h3>The thing about us is we think big, huge</h3>
                        <p>The FINA World Short Course Championships were due to be held in Abu Dhabi, and our brief was to create the launch film. Our client, the Abu Dhabi Sports Council, wanted to centre the film around Abu Dhabi’s rich pearl diving heritage, as well as celebrating the new Yas Bay Arena where the competition was to be held.</p>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature </p>
                    </div>


                </div>


            </div>



            

        </div>




        <div class="row pb-100">


            <div class="col-lg-5 col-md-12 ">
                <div class="video-details-item">
                    <div class="video-introduce  ">
                        <ul>
                            <li>
                                <span>Client</span>
                                <h4>Sahyadri Bio Labs</h4>
                                
                            </li>
                            <li>
                                <span>Date</span>
                                <h4>December 21, 2020</h4>
                                
                            </li>
                            <li>
                                <span>Producer</span>
                                <h4>Adpedia Films</h4>
                                
                            </li>
                            <li>
                                <span>Director</span>
                                <h4>Remson John Pellissery
                                </h4>
                                
                            </li>
                            <li>
                                <span>Cinematography</span>
                                <h4>Deepesh Raj
                                </h4>
                                
                            </li>
                             
                        </ul>
                    </div>

                     
                </div>


            </div>



            <div class="col-lg-7 col-md-12  ">
                <div class="video-details-item">
                    <div class="video-details-video icon-visible">
                        <img src="assets/img/portfolio/p4.jpg" alt="Work">
                        <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                    </div>
                </div>

            </div>

        </div>


 




        <div class="row">
            <div class="col-lg-12  col-md-12 video-details-item"> 
                <div class="  video-flim">
                    <h3  >Project Gallery</h3>
                </div>
                </div>
            <div class="col-lg-4 col-md-6 col-md-12">
                <div class="video-details-item">
                    <div class="video-details-video">
                        <img src="assets/img/portfolio/p1.jpg" alt="Work">
                        <a data-fancybox href="assets/img/portfolio/p1.jpg" class=""><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>
            <div class="col-lg-4 col-md-6 col-md-12">
                <div class="video-details-item">
                    <div class="video-details-video">
                        <img src="assets/img/portfolio/p3.jpg" alt="Work">
                        <a data-fancybox href="assets/img/portfolio/p3.jpg" class=""><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>
            <div class="col-lg-4 col-md-6 col-md-12">
                <div class="video-details-item">
                    <div id="single-images" class="video-details-video">
                        <img src="assets/img/portfolio/p4.jpg" alt="Work">
                        <a data-fancybox href="assets/img/portfolio/p4.jpg" class=""><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>
            <div class="col-lg-4 col-md-6 col-md-12">
                <div class="video-details-item">
                    <div id="single-images" class="video-details-video">
                        <img src="assets/img/portfolio/p2.jpg" alt="Work">
                        <a data-fancybox href="assets/img/portfolio/p2.jpg" class=""><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-md-6 col-md-12">
                <div class="video-details-item">
                    <div id="single-images" class="video-details-video">
                        <img src="assets/img/portfolio/p4.jpg" alt="Work">
                        <a data-fancybox href="assets/img/portfolio/p4.jpg" class=""><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>

            <div class="col-lg-4 col-md-6 col-md-12">
                <div class="video-details-item">
                    <div class="video-details-video">
                        <img src="assets/img/portfolio/p3.jpg" alt="Work">
                        <a data-fancybox href="assets/img/portfolio/p3.jpg" class=""><i class='bx bx-image'></i></a>
                    </div>
                </div>

            </div>
             
        </div>



        
    </div>
</div>



<div class="video-details-area pt-100 pb-100 behind-scenes">
    <div class="container">
        <div class="row ">

            <div class="col-lg-12  col-md-12 video-details-item"> 
                <div class=" video-flim">
                    <h3 class=" ">Behind The Scenes</h3>
                </div>
                </div>


                <div class="col-lg-4 col-md-6 col-md-12">
                    <div class="video-details-item">
                        <div class="video-details-video ">
                            <img src="assets/img/portfolio/p1.jpg" alt="Work">
                            <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                        </div>
                    </div>
    
                </div>
                <div class="col-lg-4 col-md-6 col-md-12">
                    <div class="video-details-item">
                        <div class="video-details-video">
                            <img src="assets/img/portfolio/p3.jpg" alt="Work">
                            <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                        </div>
                    </div>
    
                </div>
                <div class="col-lg-4 col-md-6 col-md-12">
                    <div class="video-details-item">
                        <div id="single-images" class="video-details-video">
                            <img src="assets/img/portfolio/p4.jpg" alt="Work">
                            <a data-fancybox href="assets/img/portfolio/p4.jpg" class=""><i class='bx bx-image'></i></a>
                        </div>
                    </div>
    
                </div>

                
                <div class="col-lg-4 col-md-6 col-md-12">
                    <div class="video-details-item">
                        <div class="video-details-video">
                            <img src="assets/img/portfolio/p3.jpg" alt="Work">
                            <a data-fancybox href="assets/img/portfolio/p3.jpg" class=""><i class='bx bx-image'></i></a>
                        </div>
                    </div>
    
                </div>

                <div class="col-lg-4 col-md-6 col-md-12">
                    <div class="video-details-item">
                        <div class="video-details-video ">
                            <img src="assets/img/portfolio/p1.jpg" alt="Work">
                            <a data-fancybox href="assets/img/portfolio/p1.jpg" class=""><i class='bx bx-image'></i></a>
                        </div>
                    </div>
    
                </div>
                
                <div class="col-lg-4 col-md-6 col-md-12">
                    <div class="video-details-item">
                        <div class="video-details-video">
                            <img src="assets/img/portfolio/p3.jpg" alt="Work">
                            <a data-fancybox href="assets/img/portfolio/p3.jpg" class=""><i class='bx bx-image'></i></a>
                        </div>
                    </div>
    
                </div>
             
        </div>
        </div>
        </div>