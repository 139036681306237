<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.svg" width="40" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.svg" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">

                            <a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home </a>


                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink="/about-us" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">About </a>


                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink="/services" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services </a>


                        </li>

                        

                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink="/portfolio" class="nav-link" 
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">Portfolio </a>


                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink="/blog" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog </a>


                        </li>



                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>

                        <div class="social-menu">
                            <h3>Our Social Contact</h3>

                            <ol>
                                <li><a href="https://www.facebook.com/Adpedia-films-104097221831347" target="_blank"><i
                                            class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://www.instagram.com/adpedia/" target="_blank"><i
                                            class='bx bxl-instagram'></i></a></li>
                                <li><a href="https://www.youtube.com/channel/UCoEKAY-s4P0SjnU5W8yT_NA"
                                        target="_blank"><i class='bx bxl-youtube'></i></a></li>
                                <li><a href="https://vimeo.com/user46609578" target="_blank"><i
                                            class='bx bxl-vimeo'></i></a>
                                </li>
                            </ol>
                        </div>
                    </ul>



                    <div class="side-nav">


                        <button type="button" class="btn modal-btn" data-toggle="modal" data-target="#myModalRight">
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>

<div id="myModalRight" class="modal fade modal-right" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <img class="side-logo" src="assets/img/logo-films.svg" alt="Logo">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
            </div>

            <div class="modal-body">
                <h2>About Us</h2>
                <p>Adpedia has been dedicated to providing ad film-making services for more than eight years. The
                    experienced and passionate crew of filmmakers with a knack for innovation ensures exceptional
                    quality and service.
                </p>

                <p>

                    We are a full-service company offering content creation, creative direction, pre-production,
                    production, cinematography, editing, multimedia, duplication services, and a particular passion for
                    storytelling. And this is our forte, from concept to completion
                    in ad film-making.
                </p>





                <div class="social-area">
                    <h3>Our Social Contact</h3>

                    <ul>
                        <li><a href="https://www.facebook.com/Adpedia-films-104097221831347" target="_blank"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/adpedia/" target="_blank"><i
                                    class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCoEKAY-s4P0SjnU5W8yT_NA" target="_blank"><i
                                    class='bx bxl-youtube'></i></a></li>
                        <li><a href="https://vimeo.com/user46609578" target="_blank"><i class='bx bxl-vimeo'></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>