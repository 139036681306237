<div class="page-title-area page-title-area-two">
    <div class="container">
        <div class="page-title-item">
            <h2>About</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li>About</li>
            </ul>
        </div>
    </div>
</div>



<div class="history-area pt-100 pb-70">
    <div class="container-fluid p-0">
        <div class="cd-horizontal-timeline">



            <div class="events-content">
                <ul>
                    <li class="selected" data-date="01/01/2005">
                        <div class="row align-items-center m-0">
                            <div class="col-lg-5 col-md-12">
                                <div class="history-img">
                                    <img src="assets/img/about-in.svg" alt="History">
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                                <div class="history-content">

                                    <div class="section-title sm-left">
                                        <!-- <span class="sub-title">About Us</span> -->
                                        <h2>Adpedia Films</h2>
                                    </div>



                                    <h2>Let’s make great things together!</h2>
                                    <p>Adpedia has been dedicated to providing ad film-making services for more than
                                        eight years. The experienced and passionate crew of filmmakers with a knack for
                                        innovation ensures exceptional quality and service.

                                    </p>

                                    <p> We are a full-service company offering content creation, creative direction,
                                        pre-production, production, cinematography, editing, multimedia, duplication
                                        services, and a particular passion for storytelling. And this
                                        is our forte, from concept to completion in ad film-making.


                                    </p>
                                    <p>
                                        Adpedia becomes different by taking and presenting high-quality projects and
                                        working on personalized one to one service everything from beginning to end
                                        while maintaining tight deadlines at the best price. Our expert team brainstorms
                                        ideas and crafts
                                        the best one into eye-catching video content to bring out the message you want
                                        the audience to perceive.


                                    </p>
                                    <p> We use the latest technologies and ensure the best quality and industry
                                        standards to produce spectacular outputs. We are confident in our right amount
                                        of experience and talent to give you peace of mind during the entire
                                        production process.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </li>


                </ul>
            </div>
        </div>
    </div>
</div>

<section class="product-area product-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Services</span>
            <h2>What We Do</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="product-item">
                    <img src="assets/img/service-1.jpg" alt="Services">
                    <h3>Film Production</h3>

                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="product-item">
                    <img src="assets/img/service-2.jpg" alt="Services">
                    <h3>Creative Direction</h3>

                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="product-item">
                    <img src="assets/img/service-3.jpg" alt="Services">
                    <h3>Content Production</h3>

                </div>
            </div>
        </div>
    </div>
</section>




<section class="  pt-100  pb-70 ">
    <div class="container">
        <div class="section-title section-title-two">

            <div class="row justify-content-center">
                <div class="col-md-8">
                    <h3 class="mb-3">Do you need an ad film to support your brand?
                    </h3>

                    <p>We are the perfect partner for your project needs. <br> We are there from creating a thread,
                        writing, casting, storyboards, production, editing, and voiceover talent. <br> Take the concept
                        from you and make the outrageous output. <br> Don't worry, even if you're not sure what type of
                        ad film you want to make, Our team can advise you with respect to your customer base, your
                        budget, and the ultimate aim of your ad film.
                    </p>


                    <a class="start-btn" routerLink="/contact">Get Started</a>



                </div>
            </div>
        </div>

    </div>
</section>