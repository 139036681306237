import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  title = 'Ad Film Making Companies Kerala | Best Advertising Company';

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title); 
    this.metaService.updateTag(
      { content: 'Looking for the Best Advertising Company in Kerala? Then, contact Adpedia Films, one of the top Ad Film Making Companies in Kerala & get eye-catching videos.'} , 'name="description"'
    );  this.metaService.updateTag(
      { content: ''} , 'name="keywords"'
    ); 
  }

}
