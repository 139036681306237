import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  title = 'Ad Film Making Agency in Kerala | Ad Film Making Services'
  constructor( private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.updateTag(
      { content: 'Adpedia Films which is the top Ad Film Making Agency in Kerala will help you to get the best Ad Film Making Services such as content creation & production.'} , 'name="description"'
    );
  }

}
