
import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  title = 'Best Advertising Agencies in Kerala | Ad Film Makers Kerala'
  constructor( private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.updateTag(
      { content: 'Adpedia Films, one of the Best Advertising Agencies in Kerala helps you create eye-catching video content. Get in touch with this top Ad Film Makers in Kerala. '} , 'name="description"'
    );
  }

}

