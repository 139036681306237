import { Component, OnInit } from '@angular/core';
import { Fancybox } from '@fancyapps/ui'

@Component({
  selector: 'app-portfolio-details',
  templateUrl: './portfolio-details.component.html',
  styleUrls: ['./portfolio-details.component.scss']
})
export class portfolioDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    Fancybox.bind("[data-fancybox]", {
      infinite: false,
    })
  }

}
