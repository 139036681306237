<div class="banner-wrap">
    <div class="banner-slider owl-carousel">
        <div class="banner-area banner-img-one">

            <video src="assets/img/bannervideo.mp4" loop muted autoplay></video>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="banner-content">
                        <h1>Adpedia Films</h1>
                        <p>The complete solution from concept to completion in ad film-making</p>
                        <a class="banner-btn" routerLink="/about-us">know More</a>
                    </div>
                </div>
            </div>
        </div>




    </div>

    <div class="banner-social">
        <span>Follow us on:</span>
        <ul>
            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
        </ul>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>



<div class="history-area pt-100 pb-70">
    <div class="container-fluid p-0">
        <div class="cd-horizontal-timeline">
            <!-- <div class="section-title">
                <span class="sub-title">About Us</span>
                <h2>Adpedia Films</h2>
            </div> -->



            <div class="events-content">
                <ul>
                    <li class="selected" data-date="01/01/2005">
                        <div class="row align-items-end m-0">
                            <div class="col-lg-5 col-md-12">
                                <div class="history-img">
                                    <img src="assets/img/about-vector.svg" alt="History">
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                                <div class="history-content">
                                    <h2>Let’s make great things together!</h2>
                                    <p>Adpedia has been dedicated to providing ad film-making services for more than
                                        eight years. The experienced and passionate crew of filmmakers with a knack for
                                        innovation ensures exceptional quality and service..</p>

                                    <p>We are a full-service company offering content creation, creative direction,
                                        pre-production, production, cinematography, editing, multimedia, duplication
                                        services, and a particular passion for storytelling. And this
                                        is our forte, from concept to completion in... <a class="read-more"
                                            routerLink="/about-us">read more </a>
                                    </p>
                                    <div class="row">
                                        <div class="col-sm-6 col-lg-4">
                                            <div class="history-inner">
                                                <i class=' '><img src="assets/img/film.svg" alt=""></i>
                                                <h3>FILM PRODuction</h3>

                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-lg-4">
                                            <div class="history-inner">
                                                <i class=' '><img src="assets/img/direction.svg" alt=""></i>

                                                <h3>Creative Direction</h3>

                                            </div>
                                        </div>

                                        <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                                            <div class="history-inner">
                                                <i class=' '><img src="assets/img/content-production.svg" alt=""></i>

                                                <h3>Content Production</h3>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>


                </ul>
            </div>
        </div>
    </div>
</div>


<section class="service-area ptb-100">
    <div class="container">
        <div class="section-title section-title-two ">
            <span class="sub-title">Our Features</span>
            <h2>Why Adpedia</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-video-camera-2'></i>
                    <div class="service-inner">
                        <h3>A complete ad film making agency</h3>
                        <p>Our experienced team consists of creative writers, visual creators, editors,
                            cinematographers, and specialists to ensure that we're delivering high-quality evergreen
                            video content for your businesses & organizations. We deliver
                            a complete project by exploring minds and exchanging ideas from the start to end
                        </p>

                        <a class="read-more" routerLink="/services" fragment="why">read more </a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class='flaticon-video-camera-1'></i>
                    <div class="service-inner">
                        <h3>High-Tech</h3>
                        <p>ADPEDIA utilizes the latest equipment to achieve high-tech and quality video solutions.
                            unique photography, Drone aerial shots: interior & outdoors by FAA licensed drone operators,
                            VR/AR, time-lapse, Google Maps photography, 3D
                            interior space mapping. Our experienced team captures superior footage, creating exceptional
                            results on all viewing platforms utilizing the latest high-tech equipment and techniques.
                        </p>
                        <a class="read-more" routerLink="/services" href="test">read more </a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class=''><img src="assets/img/teamwork.svg" alt=""></i>

                    <div class="service-inner">
                        <h3>Teamwork makes the dream work</h3>
                        <p>Adpedia has a very collaborative team that is unmatched in creativity, passion, and talent.
                            The team is always trying to learn new techniques, embrace new ideas, come up with new
                            concepts, and implement them, which makes our work
                            distinctive and unique. </p>
                        <a class="read-more" routerLink="/services" href="services#why">read more </a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">

                    <i class=''><img src="assets/img/trust.svg" alt=""></i>
                    <div class="service-inner">
                        <h3>Trustworthy relationship</h3>
                        <p>We love to establish a trustworthy relationship with you and genuinely care about your
                            business. We take the time to understand your communication objectives and target audience
                            to create a unique ad that reflects your brand values
                            and the story you want to tell. We are more than a creative agency, and we are your media
                            partner. </p>

                        <a class="read-more" routerLink="/services" href="services#why">read more </a>
                    </div>

                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class=''><img src="assets/img/addon.svg" alt=""></i>

                    <div class="service-inner">
                        <h3>A full range of add-on services</h3>
                        <p>we offer a full range of add-on services such as: <br>1) Subtitling language &nbsp;
                            2) Re-versioning
                        </p>
                        <a class="read-more" routerLink="/services" href="services#why">read more </a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="service-item">
                    <i class=''><img src="assets/img/minimal-expense.svg" alt=""></i>
                    <div class="service-inner">
                        <h3>At minimal expense</h3>
                        <p>An everlasting output and maximum satisfaction for you at a minimal expense will be given. It
                            is your turn to share your suggestions, and we are free to cooperate with you on a
                            customized budget in Ad filmmaking. </p>
                        <a class="read-more" routerLink="/services" href="services#why">read more </a>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>


<section class="video-area pb-100">
    <div class="container-fluid p-0">
        <!-- <div class="section-title">
            <span class="sub-title">Watch Video</span>
            <h2>Adpedia High Quality Video</h2>
        </div> -->

        <div class="row m-0 align-items-end">
            <div class="col-lg-4 p-0">
                <div class="video-item video-two">
                    <img src="assets/img/home-one/video1.jpg" alt="Video">
                    <a href="https://vimeo.com/267359070" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>

            <div class="col-lg-4 p-0">
                <div class="video-item">
                    <img src="assets/img/home-one/video2.jpg" alt="Video">
                    <a href="https://vimeo.com/206021211" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>

            <div class="col-lg-4 p-0">
                <div class="video-item video-two">
                    <img src="assets/img/home-one/video3.jpg" alt="Video">
                    <a href="https://vimeo.com/267359681" class="popup-youtube"><i
                            class='bx bx-play'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

 


<div class="week-area  ">

    <div class="container-fluid p-0">
        <div class="section-title">
            <span class="sub-title">Our Works</span>
            <h2>Featured Works</h2>
        </div>

    
    </div>

    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-6">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/work5.jpg" alt="Week">
                    <a class="icon"><i class='bx bx-play'></i></a>
                    <div class="weekhover">
                        <div class="inner">
                            <h3>Sahyadri bio labs</h3>
                            <p>Sahyadri bio labs (SBL) is a specialist in hair care. Years of research based on advanced biotechnology are the basis for the genesis of Amrut Veni range of hair care products.</p>
                        </div>

                        <div class="btn-wrapper">
                            <a routerLink="/portfolio/portfolio-sahyadri" href="" class="btn">Read Case Study</a>
                            
                            <a href="" routerLink="/portfolio/portfolio-sahyadri"  class="btn"> View More </a>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/work2.jpg" alt="Week">
                    <a  href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube icon"><i class='bx bx-play'></i></a>
                    <div class="weekhover">
                        <div class="inner">
                            <h3>Urban stitches</h3>
                            <p>We take a look at the rich history of pearl diving
                                in the UAE and see how this translates into modern
                                day sporting competition</p>
                        </div>

                        <div class="btn-wrapper">
                            <a routerLink="/portfolio/portfolio-details" href="" class="btn">Read Case Study</a>
                            <a href="" href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube btn"> Watch Video </a>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/work3.jpg" alt="Week">
                    <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube icon"><i class='bx bx-play'></i></a>
                    <div class="weekhover">
                        <div class="inner">
                            <h3>Sew N Tell - Sunglasses</h3>
                            <p>We take a look at the rich history of pearl diving
                                in the UAE and see how this translates into modern
                                day sporting competition</p>
                        </div>

                        <div class="btn-wrapper">
                            <a routerLink="/portfolio/portfolio-details" href="" class="btn">Read Case Study</a>
                            <a href="" href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube btn"> Watch Video </a>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/work4.jpg" alt="Week">
                    <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube icon"><i class='bx bx-play'></i></a>
                    <div class="weekhover">
                        <div class="inner">
                            <h3>Urban stitches</h3>
                            <p>We take a look at the rich history of pearl diving
                                in the UAE and see how this translates into modern
                                day sporting competition</p>
                        </div>

                        <div class="btn-wrapper">
                            <a routerLink="/portfolio/portfolio-details" href="" class="btn">Read Case Study</a>
                            <a href="" href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube btn"> Watch Video </a>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    </div>
</div>


<section class="service-area pt-100  pb-70 ">
    <div class="container">
        <div class="section-title section-title-two">

            <div class="row justify-content-center">
                <div class="col-md-8">
                    <h3 class="mb-3">Do you need an ad film to support your brand?
                    </h3>

                    <p>We are the perfect partner for your project needs. <br> We are there from creating a thread,
                        writing, casting, storyboards, production, editing, and voiceover talent. <br> Take the concept
                        from you and make the outrageous output. <br> Don't worry, even if you're not sure what type of
                        ad film you want to make, Our team can advise you with respect to your customer base, your
                        budget, and the ultimate aim of your ad film.
                    </p>





                    <a class="start-btn" routerLink="/contact">Get Started</a>



                </div>
            </div>
        </div>

    </div>
</section>

<!-- 
<section class="team-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Team</span>
            <h2>Directors Of Adpedia</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-top">
                        <img src="assets/img/home-one/team1.jpg" alt="Team">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram-alt'></i></a></li>
                        </ul>
                    </div>
                    <div class="team-bottom">
                        <h3>Shinelal CP</h3>
                        <span>Director</span>
                    </div>
                </div>
            </div>

            
            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-top">
                        <img src="assets/img/home-one/team3.jpg" alt="Team">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram-alt'></i></a></li>
                        </ul>
                    </div>
                    <div class="team-bottom">
                        <h3>Praveen CD</h3>
                        <span>Director</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-top">
                        <img src="assets/img/home-one/team4.jpg" alt="Team">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram-alt'></i></a></li>
                        </ul>
                    </div>
                    <div class="team-bottom">
                        <h3>Abhinas PS</h3>
                        <span>Director</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-top">
                        <img src="assets/img/home-one/team2.jpg" alt="Team">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram-alt'></i></a></li>
                        </ul>
                    </div>
                    <div class="team-bottom">
                        <h3>Maneesh MU</h3>
                        <span>Sub. Director</span>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section> 

<section class="testimonial-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonial</span>
            <h2>What Customer’s Say</h2>
        </div>

        <div class="testimonial-slider owl-theme owl-carousel">
            <div class="testimonial-item">
                <i class='bx bxs-quote-alt-left'></i>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis Risus commodo viverra maecenas accumsan lacus vel facilisis</p>
                <h3>Wahing Bashon</h3>
            </div>

            <div class="testimonial-item">
                <i class='bx bxs-quote-alt-left'></i>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                <h3>Tom Henry</h3>
            </div>

            <div class="testimonial-item">
                <i class='bx bxs-quote-alt-left'></i>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.</p>
                <h3>Machiel Shon</h3>
            </div>
        </div>
    </div>
</section>
-->