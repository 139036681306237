import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PortFolioRoutingModule } from './portfolio.routing-modules';
import { PortfolioComponent} from './portfolio.component'
import { portfolioDetailsComponent} from './portfolio-details/portfolio-details.component';
import { PortfolioSahyadriComponent } from './portfolio-sahyadri/portfolio-sahyadri.component'

@NgModule({
    declarations: [
        PortfolioComponent,
        portfolioDetailsComponent,
        PortfolioSahyadriComponent
    ],
    imports: [
        BrowserModule,
        PortFolioRoutingModule,
         
    ],
    providers: [],
    bootstrap: [PortfolioComponent]
})
export class portfolioModule { }
