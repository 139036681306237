<div class="page-title-area page-title-area-services">
    <div class="container">
        <div class="page-title-item">
            <h2>What We Do</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li>Services</li>
            </ul>
        </div>
    </div>
</div>



<section class="product-area product-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">Our Services</span> -->
            <h2>What We Do</h2>

            <h5 class="sub">The visual treat from Adpedia spreads over the generations</h5>
            <p>
                Complex or abstract, video content can explain processes and ideas in a simple yet effective way. The
                powerful videos move the world forward. Our team uses a range of unique styles and modern techniques to
                bring ideas to life, and we will discuss the best choice for your project.
            </p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="product-item">
                    <img src="assets/img/service-1.jpg" alt="Services">
                    <h3>Film Production</h3>

                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="product-item">
                    <img src="assets/img/service-2.jpg" alt="Services">
                    <h3>Creative Direction</h3>

                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="product-item">
                    <img src="assets/img/service-3.jpg" alt="Services">
                    <h3>Content Production</h3>

                </div>
            </div>
        </div>
    </div>
</section>


<section class="service-area inner ptb-100 " id="why">
    <div class="container">
        <div class="section-title section-title-two">
            <span class="sub-title">Our Features</span>
            <h2>Why Adpedia</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-6">
                <div class="service-item">
                    <i class='flaticon-video-camera-2'></i>
                    <div class="service-inner">
                        <h3>A complete ad film making agency</h3>
                        <p>Our experienced team consists of creative writers, visual creators, editors,
                            cinematographers, and specialists to ensure that we're delivering high-quality evergreen
                            video content for your businesses & organizations. We deliver
                            a complete project by exploring minds and exchanging ideas from the start to end
                        </p>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="service-item">
                    <i class='flaticon-video-camera-1'></i>
                    <div class="service-inner">
                        <h3>High-Tech</h3>
                        <p>ADPEDIA utilizes the latest equipment to achieve high-tech and quality video solutions.
                            unique photography, Drone aerial shots: interior & outdoors by FAA licensed drone operators,
                            VR/AR, time-lapse, Google Maps photography, 3D
                            interior space mapping. Our experienced team captures superior footage, creating exceptional
                            results on all viewing platforms utilizing the latest high-tech equipment and techniques.
                        </p>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="service-item">
                    <i class=''><img src="assets/img/teamwork.svg" alt=""></i>

                    <div class="service-inner">
                        <h3>Teamwork makes the dream work</h3>
                        <p>Adpedia has a very collaborative team that is unmatched in creativity, passion, and talent.
                            The team is always trying to learn new techniques, embrace new ideas, come up with new
                            concepts, and implement them, which makes our work
                            distinctive and unique. </p>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="service-item">

                    <i class=''><img src="assets/img/trust.svg" alt=""></i>
                    <div class="service-inner">
                        <h3>Trustworthy relationship</h3>
                        <p>We love to establish a trustworthy relationship with you and genuinely care about your
                            business. We take the time to understand your communication objectives and target audience
                            to create a unique ad that reflects your brand values
                            and the story you want to tell. We are more than a creative agency, and we are your media
                            partner. </p>


                    </div>

                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="service-item">
                    <i class=''><img src="assets/img/addon.svg" alt=""></i>

                    <div class="service-inner">
                        <h3>A full range of add-on services</h3>
                        <p>we offer a full range of add-on services such as: <br>1) Subtitling language <br>
                            2) Re-versioning
                        </p>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="service-item">
                    <i class=''><img src="assets/img/minimal-expense.svg" alt=""></i>
                    <div class="service-inner">
                        <h3>At minimal expense</h3>
                        <p>An everlasting output and maximum satisfaction for you at a minimal expense will be given. It
                            is your turn to share your suggestions, and we are free to cooperate with you on a
                            customized budget in Ad filmmaking. </p>

                    </div>
                </div>
            </div>


        </div>
    </div>
</section>



<section class="get-started pt-100  pb-70 ">
    <div class="container">
        <div class="section-title section-title-two">

            <div class="row justify-content-center">
                <div class="col-md-8">
                    <h3 class="mb-3">Do you need an ad film to support your brand?
                    </h3>

                    <p>We are the perfect partner for your project needs. <br> We are there from creating a thread,
                        writing, casting, storyboards, production, editing, and voiceover talent. <br> Take the concept
                        from you and make the outrageous output. <br> Don't worry, even if you're not sure what type of
                        ad film you want to make, Our team can advise you with respect to your customer base, your
                        budget, and the ultimate aim of your ad film.
                    </p>


                    <a class="start-btn" routerLink="/contact">Get Started</a>



                </div>
            </div>
        </div>

    </div>
</section>