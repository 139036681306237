import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component'; 
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component'; 
import { AboutUsComponent } from './components/pages/about-us/about-us.component';  
import { ServicesComponent } from './components/pages/services/services.component'; 
import { BlogComponent } from './components/pages/blog/blog.component'; 
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component'; 
import { ContactComponent } from './components/pages/contact/contact.component';
import { portfolioModule } from './components/pages/portfolio/portfolio.module';


const routes: Routes = [
    {path: '', component: HomeOneComponent}, 
    {path: 'coming-soon', component: ComingSoonComponent},     
    {path: 'about-us', component: AboutUsComponent},     
    {path: 'services', component: ServicesComponent},     
    {path: 'portfolio',  loadChildren: () => portfolioModule},     
    {path: 'blog', component: BlogComponent},     
    {path: 'blog-details', component: BlogDetailsComponent},     
    {path: 'contact', component: ContactComponent},
    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }